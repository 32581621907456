import React from 'react';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'study-report-share'])),
    },
  };
};

const Wukong: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Head>
        <title>demo</title>
      </Head>
      <div>{t('description')}</div>
    </div>
  );
};

export default Wukong;
